import { createRouter, createWebHistory, RouteRecordRaw, createWebHashHistory } from 'vue-router'
import HomeView from '../views/homePage/HomeView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    // component: () => import('@/views/index.vue')
    component: HomeView
  },
  {
    path: '/safety',
    name: 'safety',
    component: () => import('@/views/safety/safety.vue')
  },
  {
    path: '/progress',
    name: 'progress',
    component: () => import('@/views/progress/progress.vue')
  },
  {
    path: '/page',
    name: 'page',
    component: () => import('@/views/page.vue')
  },
]

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
