import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/style/index.less'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import SvgIcon from '@/components/SvgIcon.vue'
import '@/assets/iconfont/index.js'

createApp(App).use(store).use(router).use(ElementPlus).component("svg-icon",SvgIcon).mount('#app')
